import React, { FC } from 'react';
import loadable from '@loadable/component';
import { graphql, useStaticQuery } from 'gatsby';
import { LocationProvider, useLocation } from '@reach/router';
import { Global } from '@emotion/react';
import kebabCase from 'lodash/kebabCase';

import SEO from '@/app/seo/SEO';
import commonGlobal from '@/app/common/style/common';
import { ContentSection } from '@/components/sections/content/Content';
import { FooterSection } from '@/components/sections/footer/Footer';
import { Sections } from '@/components/sections/Section';
import { Wrapper } from './styles';
import { Provider, useContext } from './context';
import { AppProps } from './types';

const Sidebar = loadable(() => import(`@/components/shared/Sidebar/Sidebar`));
// const CookieConsentDialog = loadable(
//   () => import(`@/app/cookie-consent/CookieConsentDialog`),
// );

export interface ContentfulLayout {
  id: string;
  name: string;
  sections: [ContentSection | FooterSection];
}

export interface GetLayoutResponse {
  contentfulLayout: ContentfulLayout;
}

/**
 * App component
 */
const App: FC<AppProps> = ({ children }) => {
  const [
    {
      page: { meta },
    },
  ] = useContext();
  const { pathname } = useLocation();
  const page = kebabCase(pathname);
  const { contentfulLayout: layout } =
    useStaticQuery<GetLayoutResponse>(graphql`
      query GetLayout {
        contentfulLayout(name: { eq: "default" }) {
          ...Layout
        }
      }
    `);

  if (!layout) {
    return <></>;
  }

  return (
    <Wrapper className={`page-${page || `home`}`}>
      <SEO {...meta} />
      <Global styles={commonGlobal} />
      <Sidebar />
      <Sections ignoreContainer sections={layout.sections || []}>
        {children}
      </Sections>
      {/* <CookieConsentDialog /> */}
    </Wrapper>
  );
};

const Root: FC<AppProps> = (props) => (
  <LocationProvider>
    <Provider>
      <App {...props} />
    </Provider>
  </LocationProvider>
);

export default Root;
